import './Header.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const Header = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				'& > :not(style)': {
					m: 1,
					width: '100%',
					height: '60px',
				},
			}}
		>
			<Paper
				elevation={3}
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
						m: 1,
						width: '100%',
						height: '100%',
					},
				}}
				style={{ marginBottom: '10px' }}
			>
				<h1>Easy File</h1>
			</Paper>
		</Box>
	);
};

export default Header;
