import './App.css';
import Header from './Components/Header';
import Search from './Components/Search';
import Footer from './Components/Footer';
import Main from './Components/Main';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Content from './Components/Content';

function App() {
	const API_URL = 'https://qa-api.easy-file.dircha.app/files';

	const [items, setItems] = useState([]);
	//const [newItem, setNewItem] = useState('');
	//const [search, setSearch] = useState('');
	const [fetchError, setFetchError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingMain, setIsLoadingMain] = useState(true);

	useEffect(() => {
		const fetchItems = async () => {
			try {
				const response = await fetch(API_URL);
				if (!response.ok) throw Error('Did not receive expected data!');
				const listItems = await response.json();
				setItems(listItems);
				setFetchError(null);
			} catch (err) {
				setFetchError(err.message);
			} finally {
				setIsLoading(false);
			}
		};
		fetchItems();
	}, []);

	// Send and receive data from Child component aka Search
	const [newItem, setNewItem] = useState('');
	const childToParent = (childdata) => {
		try {
			setNewItem(childdata);
			setFetchError(null);
		} catch (err) {
			setFetchError(err.message);
		} finally {
			setIsLoadingMain(false);
		}
	};

	return (
		<div className="App">
			{/* Load Header component */}
			<Header />

			{/* Load Search component only when data is loaded */}
			{isLoading && (
				<Search
					items={''}
					holdertext={'Loading...'}
				/>
			)}
			{fetchError && (
				<Search
					style={{ color: 'red' }}
					items={''}
					holdertext={`Error: ${fetchError}`}
				/>
			)}
			{!fetchError && !isLoading && (
				<Search
					items={items}
					holdertext={
						'Search by case number | defendant | claimant | case type'
					}
					childToParent={childToParent}
				/>
			)}

			{/* Load Main component only when data is loaded */}
			{isLoadingMain && (
				<Box
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						'& > :not(style)': {
							m: 1,
							width: '100%',
							height: '100%',
						},
					}}
				>
					<Paper
						elevation={3}
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							'& > :not(style)': {
								m: 1,
								width: '100%',
								height: '100%',
							},
						}}
						style={{ marginBottom: '10px' }}
					>
						<div className="LoadErr">
							~ Please search and select a case file to be displayed ~
						</div>
					</Paper>
				</Box>
			)}
			{fetchError && (
				<div
					className="LoadErr"
					style={{ color: 'red' }}
				>{`Error: ${fetchError}`}</div>
			)}
			{!fetchError && !isLoadingMain && <Main items={newItem} />}

			{/* Load Footer component */}
			<Footer />
		</div>

		// <Content />
	);
}
export default App;
