import React, { useState } from 'react';
import './Search.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const SearchBar = (props) => {
	const handleOnSearch = (string, results) => {
		// onSearch will have as the first callback parameter
		// the string searched and for the second the results.
		console.log(string, results);
	};

	const handleOnSelect = (item) => {
		// the item selected
		console.log(item);
		props.childToParent(item);
	};

	const handleOnFocus = () => {
		console.log('Focused');
	};

	const formatResult = (item) => {
		return (
			<div style={{ backgroundColor: 'white' }}>
				<table id="resultTable">
					<tbody>
						<tr>
							<td id="narrow">
								<b>FileRef:</b> {item.fileRef}
							</td>
							<td id="wide">
								<b>| Claimant :</b> {item.claimant}
							</td>
							<td id="wide">
								<b>| Defendant:</b> {item.defendant}
							</td>
							<td id="wide">
								<b>| Matter:</b> {item.matter}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				'& > :not(style)': {
					m: 1,
					width: '100%',
					height: '80px',
				},
			}}
		>
			<Paper
				elevation={3}
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
						m: 1,
						width: '100%',
						height: '100%',
					},
				}}
				style={{ marginBottom: '10px' }}
			>
				<form className="searchForm">
					<div className="searchBar">
						<ReactSearchAutocomplete
							items={props.items}
							fuseOptions={{
								shouldSort: true,
								threshold: 0.1,
								//   location: 0,
								//   distance: 100,
								//   maxPatternLength: 32,
								//   minMatchCharLength: 1,
								keys: ['fileRef', 'claimant', 'defendant', 'matter'],
							}}
							// necessary, otherwise the results will be blank
							resultStringKeyName={'fileRef'}
							onSearch={handleOnSearch}
							//onHover={handleOnHover}
							onSelect={handleOnSelect}
							onFocus={handleOnFocus}
							autoFocus
							formatResult={formatResult}
							placeholder={props.holdertext}
							showItemsOnFocus={false}
						/>
					</div>
				</form>
			</Paper>
		</Box>
	);
};

export default SearchBar;
