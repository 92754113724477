import './Main.css';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import apiRequest from './apiRequest';
import { format } from 'date-fns';
import { useState } from 'react';

const Main = ({ items, fetchError, setFetchError }) => {
	const API_URL = 'https://qa-api-easy-file-utbl2bo3ba-uc.a.run.app/files';

	const [isReadonly, setIsReadonly] = useState(true);
	const [inputStyle, setInputStyle] = useState('rgba(211, 211, 211, 0.521)');

	const [newClaiment, setClaiment] = useState(items.claimant);
	const [newDefendant, setDefendant] = useState(items.defendant);
	const [newEmployer, setEmployer] = useState(items.employer);
	const [newMatter, setMatter] = useState(items.matter);
	const [newSettled, setSettled] = useState(items.settled);
	const [newComments, setComments] = useState(items.comments);

	const handleEdit = () => {
		setIsReadonly(false);
		setInputStyle('white');
	};

	const handleSave = async (id) => {
		console.log('id : ', id);
		setIsReadonly(true);
		setInputStyle('rgba(211, 211, 211, 0.521)');

		//const myItem = items.find((item) => item.id === id);
		const updateOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				id: id,
				claimant: newClaiment,
				defendant: newDefendant,
				employer: newEmployer,
				matter: newMatter,
				settled: newSettled,
				comments: newComments,
			}),
		};
		//const reqUrl = `${API_URL}/${id}`;
		console.log('reqUrl : ', API_URL);
		console.log('updateOptions : ', updateOptions.body);
		const result = await apiRequest(API_URL, updateOptions);
		console.log('Results : ', result);
		if (result) setFetchError(result);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				'& > :not(style)': {
					m: 1,
					width: '100%',
					height: '100%',
				},
			}}
		>
			<div className="box-wrapper">
				<Paper
					elevation={3}
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						'& > :not(style)': {
							m: 1,
							width: '100%',
							height: '100%',
						},
					}}
					style={{ marginBottom: '10px', paddingBottom: '10px' }}
				>
					<div id="boxTop">
						<div id="boxTopLeft">
							<div id="wrapper">
								<div id="label">Claimant :</div>
							</div>
							<div id="wrapper">
								<TextField
									id="outlined-basic"
									multiline
									maxRows={4}
									style={{ backgroundColor: inputStyle, width: '400px' }}
									InputProps={{
										readOnly: isReadonly,
									}}
									defaultValue={items?.claimant}
									onChange={(e) => setClaiment(e.target.value)}
								/>
							</div>
						</div>

						<div id="boxTopRight">
							<div id="wrapper">
								<div id="label">Defendant :</div>
							</div>
							<div id="wrapper">
								<TextField
									id="outlined-basic"
									multiline
									maxRows={4}
									style={{ backgroundColor: inputStyle, width: '400px' }}
									InputProps={{
										readOnly: isReadonly,
									}}
									defaultValue={items?.defendant}
									onChange={(e) => setDefendant(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</Paper>

				<Paper
					elevation={3}
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						'& > :not(style)': {
							m: 1,
							width: '100%',
							height: '100%',
						},
					}}
					style={{ marginBottom: '10px' }}
				>
					<div id="boxMain">
						<div id="boxMainLeft">
							<div id="insideBox">
								<div id="wrapper">
									<div id="label">Client Ref :</div>
								</div>
								<div id="wrapper">
									<TextField
										id="outlined-basic"
										multiline
										maxRows={4}
										style={{
											backgroundColor: 'rgba(211, 211, 211, 0.521)',
											width: '400px',
										}}
										disabled
										//readOnly={true}
										value={items?.clientRef}
									/>
								</div>
							</div>
							<div id="insideBox">
								<div id="wrapper">
									<div id="label">Defendant Employer :</div>
								</div>
								<div id="wrapper">
									<TextField
										id="outlined-basic"
										multiline
										maxRows={4}
										style={{ backgroundColor: inputStyle, width: '400px' }}
										InputProps={{
											readOnly: isReadonly,
										}}
										defaultValue={items?.employer}
										onChange={(e) => setEmployer(e.target.value)}
									/>
								</div>
							</div>
							<div id="insideBox">
								<div id="wrapper">
									<div id="label">Matter :</div>
								</div>
								<div id="wrapper">
									<TextField
										id="outlined-basic"
										multiline
										maxRows={4}
										style={{ backgroundColor: inputStyle, width: '400px' }}
										InputProps={{
											readOnly: isReadonly,
										}}
										defaultValue={items?.matter}
										onChange={(e) => setMatter(e.target.value)}
									/>
								</div>
							</div>
							<div id="insideBox">
								<div id="wrapper">
									<div id="label">Date Created :</div>
								</div>
								<div id="wrapper">
									<TextField
										id="outlined-basic"
										multiline
										maxRows={4}
										style={{
											backgroundColor: 'rgba(211, 211, 211, 0.521)',
											width: '400px',
										}}
										//readOnly={true}
										disabled
										value={format(new Date(items?.date), 'dd MMM yyyy')}
										//value={new Date(items?.date).toLocaleDateString()}
										//value={items?.date.toString().slice(0, 10)}
									/>
								</div>
							</div>
							<div id="insideBox">
								<div id="wrapper">
									<div id="label">Date Last Modified :</div>
								</div>
								<div id="wrapper">
									<TextField
										id="outlined-basic"
										multiline
										maxRows={4}
										style={{
											backgroundColor: 'rgba(211, 211, 211, 0.521)',
											width: '400px',
										}}
										//readOnly={true}
										disabled
										value={format(new Date(items?.date), 'dd MMM yyyy')}
										//value={new Date(items?.date).toLocaleDateString()}
										//value={items?.date.toString().slice(0, 10)}
									/>
								</div>
							</div>
							<div id="insideBox">
								<div id="wrapper">
									<div id="label">Settled :</div>
								</div>
								<div id="wrapper">
									<FormControlLabel
										// InputProps={{
										// 	readOnly: isReadonly,
										// }}
										control={
											<Checkbox
												sx={{ '& .MuiSvgIcon-root': { fontSize: 60 } }}
												checked={
													items?.settled.toString().toUpperCase() === 'TRUE'
														? true
														: false
												}
												onChange={(e) => setSettled(e.target.checked)}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
										label={
											items?.settled.toString().toUpperCase() === 'TRUE'
												? '(Case Closed)'
												: '(Case Open)'
										}
									/>
								</div>
							</div>
						</div>
						<div id="boxMainRight">
							<div id="insideBox">
								<div id="wrapper">
									<div id="label">Comments :</div>
								</div>
							</div>
							<div id="insideBoxBig">
								<div id="wrapper">
									<textarea
										id="outlined-multiline-static"
										multiline
										style={{
											backgroundColor: inputStyle,
											width: '400px',
											height: '150px',
										}}
										InputProps={{
											readOnly: isReadonly,
										}}
										defaultValue={items?.comments
											.toString()
											.replace(/<br>/g, '\r\n')}
										onChange={(e) => setComments(e.target.value)}
										//value={('line one<br>line two<br>line three').replace(/<br>/g, '\r\n')}
									></textarea>
								</div>
							</div>
						</div>
					</div>
				</Paper>

				<Paper
					elevation={3}
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						'& > :not(style)': {
							m: 1,
							width: '100%',
							height: '100%',
						},
					}}
					style={{ marginBottom: '10px' }}
				>
					<div id="boxBottom">
						<div id="boxTopLeft">
							<div id="wrapper">
								<Stack
									direction="row"
									spacing={2}
								>
									<Button
										onClick={() => handleEdit()}
										variant="outlined"
										startIcon={<EditIcon />}
									>
										Edit
									</Button>
									<Button
										onClick={() => handleSave(items._id)}
										variant="contained"
										endIcon={<SaveIcon />}
										readOnly={isReadonly}
									>
										Save
									</Button>
								</Stack>
							</div>
						</div>

						<div id="boxTopRight">
							<div id="wrapper">
								<div id="label">FileRef :</div>
							</div>
							<div id="wrapper">
								<TextField
									id="input"
									style={{ width: '200px' }}
									//readOnly={true}
									disabled
									value={items?.fileRef}
								/>
							</div>
						</div>
					</div>
				</Paper>
			</div>
		</Box>
	);
};
export default Main;
